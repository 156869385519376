/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./style/layout.scss"
import {cookieName, getCookie} from "./cookieBanner";
import LayoutComponent from "./layoutComponent";



export default class Layout extends React.Component {
  
  constructor(props) {
    super(props)

    const cookieValue = getCookie(cookieName)

    this.state = {
      showCookieBanner: !cookieValue
    }
  }
  
  render() {
    const { id, seoTitle, title, children } = this.props

  
    return <LayoutComponent id={id} seoTitle={seoTitle} title={title}
                            showCookieBanner={this.state.showCookieBanner}
                            onHideCookieBanner={() => {this.setState({showCookieBanner: false})}}                        
            >{children}</LayoutComponent>

  }
}


const SubHeading = ({id, className, children}) => (
  <h3 id={`${id||''}`} className={`kite-subheading ${className||''}`}>{children}</h3>
)
  
const KiteLink = ({id, className, href, children}) => (
  <a href={href} id={`${id||''}`} className={`kite-subheading ${className||''}`}>{typeof children === 'string'?<strong>{children}</strong>:children}</a>
)

export {Layout, SubHeading, KiteLink}
