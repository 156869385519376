import React from 'react'
import {useBackgroundImage} from "./clouds-image";
import {TwitterSVG} from "./svg";

const Footer = ({className}) => {
  const today = new Date()
  return (
    <footer id="page-footer" style={useBackgroundImage()} className={className}>
      <div id="page-footer-kite">
        <div>
          <div id="page-footer-details">
            <div><strong>&copy;</strong> {today.getFullYear()} <strong>Kite Development &amp; Consulting Limited</strong></div>
  
            <div className="kite-locations">
              <span>London</span>
              <span>Oxford</span>
              <span>Hereford</span>
            </div>
            <div id="page-footer-twitter">
              <TwitterSVG />
              <a href="https://twitter.com/madebykite">@madebykite</a>
            </div>
          </div>
          <div id="page-footer-links">
            <a href="/privacy">Privacy &amp; Cookies Policy</a>
            <span> • </span>
            <a href="/contact">Contact</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export {Footer}