import React from "react"

function setCookie(cname, cvalue, exdays) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function getCookie(cname) {
  if (typeof document !== 'undefined') {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  } else {
    return "we are server side generated"
  }
  return "";
}

export const cookieName = 'gatsby-gdpr-google-analytics'

export class CookieBanner extends React.Component {

  
  render() {
    if (this.props.show) {
      return (<section id="cookie-banner">
        <div>
          <span>We use cookies to ensure that we give you the best experience on our website. Read our <a href="/privacy">Privacy Policy</a>.</span>
          <button
            onClick={() => {
              setCookie(cookieName, 'true', 365)
              this.props.onHide()
          }}>Okay</button>
        </div>
      </section>)
    } else return null
  }
  
}

