import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo";
import {CookieBanner} from "./cookieBanner";
import {Footer} from './footer'
import Header from "./header"



export default ({ id, seoTitle, title, children, showCookieBanner, onHideCookieBanner }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

  return (
    <>
    {typeof document === 'undefined'?
      <SEO title={seoTitle || title} />
      :null}
    <CookieBanner show={showCookieBanner} onHide={onHideCookieBanner} />
    <Header siteTitle={title || data.site.siteMetadata.title} />
    <div>
      <main id={id}>{children}</main>
      <Footer className={showCookieBanner?'banner-showing':''} />
    </div>
    </>
  )
}

