import React from "react"
import KiteSVG from "./svgs/kite"

const Header = ({ siteTitle }) => (
  <header>
    <div id="kite-logo-container">
      <a href="/"><KiteSVG /></a>
    </div>
    <div id="kite-header-text">{typeof siteTitle === 'string'?<strong>{siteTitle}</strong>:siteTitle}</div>
  </header>
)

export default Header
